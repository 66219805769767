import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import { searchKeys, columns } from "./config/index";
import Follows from "./components/follows.vue";
import cloneDeep from "lodash/cloneDeep";
import { formatTextareaToArray } from "@/utils/help";
import SeriesV2Api from '@/api/seriesV2';
import Log from "./components/log.vue";
export default {
  name: 'seriesV2',
  components: {
    Follows: Follows,
    Log: Log
  },
  data: function data() {
    return {
      createForm: this.$form.createForm(this, {
        name: this.moudle
      }),
      searchKeys: searchKeys,
      columns: columns,
      statusAr: [{
        name: '全部',
        id: ''
      }, {
        name: '生效',
        id: 0
      }, {
        name: '失效',
        id: 1
      }],
      dataSource: [],
      loading: false,
      // 分页相关
      total: 10,
      queryParams: {
        page: 1,
        size: 10
      },
      showLogModal: false,
      editLogId: ''
    };
  },
  computed: {
    module: function module() {
      // 列表/搜索等都需要根据模块来显示
      return this.$route.name;
    },
    getDataWithKey: function getDataWithKey() {
      var _this = this;
      return function (key) {
        if (key == 'status') return _this.statusAr;
      };
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    handleSearch: function handleSearch() {
      this.queryParams.page = 1;
      this.getList();
    },
    handleReset: function handleReset() {
      this.queryParams.page = 1;
      this.queryParams.size = 20;
      this.createForm.resetFields();
      this.handleSearch();
    },
    getList: function getList() {
      var _this2 = this;
      return new Promise(function (resolve) {
        _this2.createForm.validateFields(function (err, values) {
          if (!err) {
            // console.log(values);
            var formValues = cloneDeep(values);
            _this2.searchFields = formValues;
            if (formValues.date && formValues.date.length > 0) {
              formValues.updatetimestart = formValues.date[0].format("YYYY-MM-DD HH:mm:ss");
              formValues.updatetimestartend = formValues.date[1].format("YYYY-MM-DD HH:mm:ss");
              delete formValues.date;
            }
            // id多行
            formValues.ids = formatTextareaToArray(formValues.ids);
            // uid多行
            formValues.userids = formatTextareaToArray(formValues.userids);
            if (formValues.status === "") delete formValues.status;
            _this2.loading = true;
            var params = _objectSpread({
              page: _this2.queryParams.page,
              size: _this2.queryParams.size
            }, formValues);
            SeriesV2Api.findpage(params).then(function (res) {
              // console.log(res);
              _this2.loading = false;
              _this2.total = res.total;
              _this2.dataSource = res.list || [];
            }).catch(function (err) {
              _this2.dataSource = [];
            }).finally(function () {
              resolve(false);
              setTimeout(function () {
                _this2.loading = false;
              }, 3000);
            });
          } else {
            resolve(false);
            // console.log(err);
          }
        });
      });
    },
    handleAction: function handleAction(keyType) {
      var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      if (keyType == 'add') this.$router.push({
        path: '/seriesV2/add'
      });
      if (keyType == 'log') this.getLogs(value);
      if (keyType == 'edit') this.$router.push({
        path: '/seriesV2/edit?id=' + value.id
      });
      if (keyType == 'del' || keyType == 'reset') this.statusHandler(keyType, value.id);
    },
    onPageChange: function onPageChange(page, size) {
      this.queryParams.size = size;
      this.queryParams.page = page;
      this.getList();
    },
    // 查询日志
    getLogs: function getLogs(item) {
      this.showLogModal = true;
      this.editLogId = item.id;
    },
    /* 
       更改系列状态
       0 正常 1失效
    */
    statusHandler: function statusHandler(state, id) {
      var _this3 = this;
      SeriesV2Api.changeStatus({
        status: state == 'del' ? 1 : 0,
        id: id
      }).then(function (res) {
        _this3.dataSource.map(function (val) {
          if (val.id == id) _this3.$set(val, 'status', state == 'del' ? 1 : 0);
        });
      });
    }
  }
};