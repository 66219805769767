var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "series-v2"
  }, [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-bottom": "12px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          staticStyle: {
            "margin-bottom": "10px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("div", {
    staticClass: "add-box"
  }, [_c("a-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAction("add");
      }
    }
  }, [_vm._v("新建")])], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "itemcount",
      fn: function fn(itemcount) {
        return _c("span", {
          staticClass: "color-blue"
        }, [_vm._v(_vm._s(itemcount))]);
      }
    }, {
      key: "username",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("span", [_vm._v(_vm._s(record.username))]), _c("div", [_vm._v("粉丝数："), _c("Follows", {
          attrs: {
            num: record.funscount
          }
        })], 1)]);
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return _c("span", {}, [status == 0 ? _c("span", {
          staticClass: "active"
        }, [_vm._v("生效")]) : _c("span", {
          staticClass: "lose"
        }, [_vm._v("失效")])]);
      }
    }, {
      key: "action",
      fn: function fn(text, record) {
        return _c("span", {}, [_c("div", {
          staticClass: "operate-rewrite"
        }, [_c("a-button", {
          attrs: {
            type: "primary",
            size: "small"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleAction("edit", record);
            }
          }
        }, [_vm._v("管理")]), !record.status ? _c("a-button", {
          attrs: {
            ghost: "",
            type: "danger",
            size: "small"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleAction("del", record);
            }
          }
        }, [_vm._v("删除")]) : _c("a-button", {
          staticClass: "review",
          attrs: {
            size: "small"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleAction("reset", record);
            }
          }
        }, [_vm._v("恢复")]), _c("a-button", {
          attrs: {
            type: "default",
            size: "small"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleAction("log", record);
            }
          }
        }, [_vm._v("日志")])], 1)]);
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.queryParams.page,
      size: _vm.queryParams.size,
      onPageSizeChange: _vm.onPageChange,
      onShowSizeChange: _vm.onPageChange
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.queryParams, "page", $event);
      },
      "update:size": function updateSize($event) {
        return _vm.$set(_vm.queryParams, "size", $event);
      }
    }
  })], 1), _c("DPopup", {
    attrs: {
      title: "日志",
      comVisible: _vm.showLogModal,
      isClosable: true,
      "z-index": 1001,
      modalWidth: "1000px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.showLogModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("Log", {
          attrs: {
            editId: _vm.editLogId
          },
          on: {
            cancel: function cancel($event) {
              _vm.showLogModal = false;
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };