// 账号管理页面的所有接口
import { POST, GET } from '@/utils/util';
var SeriesV2Api = {
  //  列表
  findpage: function findpage() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/video/series/list', params);
  },
  // 新增数据 
  add: function add() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/video/series', params);
  },
  // 修改数据
  edit: function edit() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/video/series/".concat(params.id), params);
  },
  // 修改状态
  changeStatus: function changeStatus() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/video/series/".concat(params.id, "/status"), params);
  },
  // 修改用户可用系列的顺序
  changeSort: function changeSort() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/video/series/".concat(params.uid, "/sort"), params);
  },
  // 数据详情
  detail: function detail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/video/series/".concat(params.id));
  },
  // 用户所有可用系列
  seriesUser: function seriesUser() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/communityservice/video/series/".concat(params.uid, "/list"));
  },
  // 日志
  logList: function logList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST('/api/communityservice/logs/findpage', params);
  },
  // 查询所有的作品 
  worksList: function worksList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET('/api/communityservice/videos/findpage/v2', params);
  }
};
export default SeriesV2Api;