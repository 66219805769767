var columns = [{
  title: '系列ID',
  dataIndex: 'id',
  key: 'id',
  width: '13%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '更新时间',
  dataIndex: 'updatetime',
  key: 'updatetime',
  width: '15%',
  // sorter: false,
  scopedSlots: {
    customRender: 'updatetime'
  }
}, {
  title: '系列名称',
  dataIndex: 'name',
  key: 'name',
  width: '27%',
  scopedSlots: {
    customRender: 'name'
  }
}, {
  title: '系列顺序',
  dataIndex: 'sort',
  key: 'sort',
  width: '12%',
  scopedSlots: {
    customRender: 'sort'
  }
}, {
  title: '关联EP数',
  dataIndex: 'itemcount',
  key: 'itemcount',
  width: '15%',
  scopedSlots: {
    customRender: 'itemcount'
  }
}, {
  title: '创建人',
  dataIndex: 'username',
  key: 'username',
  width: '15%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '60px',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: '180px',
  scopedSlots: {
    customRender: 'action'
  }
}];
var logColumns = [{
  title: '日志ID',
  dataIndex: 'id',
  key: 'id',
  width: '25%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '更新时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '20%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '备注',
  dataIndex: 'memo',
  key: 'memo',
  scopedSlots: {
    customRender: 'memo'
  }
}];
export { columns, logColumns };