import "core-js/modules/es.function.name.js";
import SeriesV2Api from '@/api/seriesV2';
import { logColumns } from "../config";
export default {
  name: "seriesV2Log",
  components: {},
  props: {
    editId: [String]
  },
  data: function data() {
    return {
      spinning: false,
      logColumns: logColumns,
      dataSource: [],
      // 模板列表
      total: 0,
      page: 1,
      size: 30
    };
  },
  computed: {
    module: function module() {
      return this.$route.name;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.spinning = true;
      var params = {
        contentid: this.editId,
        page: this.page,
        size: this.size,
        type: 'VIDEO_SERIES_OPERATION'
      };
      SeriesV2Api.logList(params).then(function (res) {
        _this.spinning = false;
        _this.dataSource = res.list || [];
        _this.total = res.total || 0;
      }).catch(function () {
        _this.spinning = false;
      });
    },
    cancel: function cancel() {
      this.$emit("cancel");
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    }
  }
};