var searchKeys = [{
  key: "ids",
  label: "系列ID",
  placeholder: "系列ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "name",
  label: "关键词",
  placeholder: "系列名称关键词",
  required: false,
  rules: [],
  input: true,
  trim: true
}, {
  key: 'status',
  label: '状态',
  placeholder: '状态',
  required: true,
  rules: [],
  select: true,
  initialValue: 0
}, {
  key: "userids",
  label: "创建人ID",
  placeholder: "创建人ID，多个一行一个",
  required: false,
  rules: [],
  textarea: true,
  trim: false,
  hidden: true
}, {
  key: "date",
  label: "更新时间",
  format: "YYYY-MM-DD HH:mm:ss",
  placeholder: ["更新时间_开始", "更新时间_结束"],
  required: false,
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };